{
  "name": "if-demo",
  "version": "2.0.4",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build --configuration integration",
    "build:local": "ng build",
    "watch": "ng build --watch --configuration development",
    "sr": "semantic-release --dry-run --debug"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "19.0.0",
    "@angular/cdk": "19.0.0",
    "@angular/common": "19.0.0",
    "@angular/compiler": "19.0.0",
    "@angular/core": "19.0.0",
    "@angular/forms": "19.0.0",
    "@angular/platform-browser": "19.0.0",
    "@angular/platform-browser-dynamic": "19.0.0",
    "@angular/router": "19.0.0",
    "@if/angular-core-components": "2.0.0",
    "@if/angular-hotkeys": "2.0.0",
    "rxjs": "7.8.1",
    "tailwindcss": "3.4.15",
    "tslib": "2.8.1",
    "zone.js": "0.15.0"
  },
  "devDependencies": {
    "@angular/build": "^19.0.2",
    "@angular/cli": "19.0.2",
    "@angular/compiler-cli": "19.0.0",
    "@if/semantic-release-config-node": "3.0.3",
    "@semantic-release/changelog": "6.0.3",
    "@semantic-release/commit-analyzer": "13.0.0",
    "@semantic-release/exec": "6.0.3",
    "@semantic-release/git": "10.0.1",
    "@semantic-release/gitlab": "13.2.1",
    "@semantic-release/release-notes-generator": "14.0.1",
    "autoprefixer": "10.4.20",
    "conventional-changelog-conventionalcommits": "8.0.0",
    "postcss": "8.4.49",
    "semantic-release": "24.2.0",
    "typescript": "5.6.3"
  },
  "packageManager": "pnpm@9.14.2"
}
