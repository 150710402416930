{
  "name": "if-demo",
  "version": "3.0.2",
  "scripts": {
    "postinstall": "git config core.hooksPath .githooks && chmod +x .githooks/*",
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build --configuration integration",
    "build:local": "ng build",
    "watch": "ng build --watch --configuration development",
    "sr": "semantic-release --dry-run --debug"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "19.2.0",
    "@angular/cdk": "19.2.1",
    "@angular/common": "19.2.0",
    "@angular/compiler": "19.2.0",
    "@angular/core": "19.2.0",
    "@angular/forms": "19.2.0",
    "@angular/platform-browser": "19.2.0",
    "@angular/platform-browser-dynamic": "19.2.0",
    "@angular/router": "19.2.0",
    "@if/angular-core-components": "3.0.0",
    "@if/angular-hotkeys": "2.0.1",
    "@tailwindcss/postcss": "4.0.9",
    "rxjs": "7.8.2",
    "tailwindcss": "4.0.9",
    "tslib": "2.8.1",
    "zone.js": "0.15.0"
  },
  "devDependencies": {
    "@angular/build": "19.2.0",
    "@angular/cli": "19.2.0",
    "@angular/compiler-cli": "19.2.0",
    "@if/semantic-release-config-node": "3.0.3",
    "@semantic-release/changelog": "6.0.3",
    "@semantic-release/commit-analyzer": "13.0.1",
    "@semantic-release/exec": "7.0.3",
    "@semantic-release/git": "10.0.1",
    "@semantic-release/gitlab": "13.2.4",
    "@semantic-release/release-notes-generator": "14.0.3",
    "autoprefixer": "10.4.20",
    "conventional-changelog-conventionalcommits": "8.0.0",
    "postcss": "8.5.3",
    "semantic-release": "24.2.3",
    "typescript": "5.8.2"
  },
  "packageManager": "pnpm@10.5.2"
}
