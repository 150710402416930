import {Inject, Injectable, NgZone, signal, WritableSignal} from '@angular/core';
import {LocalStorageService} from "../local-storage/local-storage.service";
import {DOCUMENT} from "@angular/common";

export enum Theme {
    LIGHT = 'light',
    DARK = 'dark',
    SYSTEM = 'system',
}

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
    isDarkTheme: WritableSignal<boolean> = signal(false);
    getTheme: WritableSignal<string> = signal('system');
    nativeMatchMediaDark: boolean = false;


    constructor(private localStorageService: LocalStorageService, @Inject(DOCUMENT) private document: Document, private ngZone: NgZone) {

        this.ngZone.runOutsideAngular(() => {
            const nativeMatchMediaDark = window.matchMedia('(prefers-color-scheme: dark)');
            this.nativeMatchMediaDark = nativeMatchMediaDark.matches;
            nativeMatchMediaDark.addEventListener('change', (event) => {
                this.ngZone.run(() => {
                    this.setSystemTheme();
                });
            });
        });

        this.getTheme.set(this.localStorageService.getItem('isDarkTheme') ?? 'system');
        // is isDarkTheme is undefined in local Storage, get it from Browser
        if (this.localStorageService.getItem('isDarkTheme') === null) {
            this.isDarkTheme.set(this.nativeMatchMediaDark);
        } else {
            // load isDarkTheme from local storage
            this.isDarkTheme.set(this.localStorageService.getItem('isDarkTheme') === 'true');
        }

        // set a flag :root for css
        this.document.documentElement.closest('html')?.classList.remove(Theme.DARK, Theme.LIGHT);
        this.document.documentElement.closest('html')?.classList.add(this.isDarkTheme() ? Theme.DARK : Theme.LIGHT);
    }

    setTheme(isDarkTheme: boolean) {
        this.isDarkTheme.set(isDarkTheme);
        // set to local storage
        this.localStorageService.setItem('isDarkTheme', this.isDarkTheme().toString());
        this.getTheme.set(this.localStorageService.getItem('isDarkTheme'));
        // set a flag :root for css
        this.document.documentElement.closest('html')?.classList.remove(Theme.DARK, Theme.LIGHT);
        this.document.documentElement.closest('html')?.classList.add(this.isDarkTheme() ? Theme.DARK : Theme.LIGHT);
    }

    setSystemTheme() {
        this.isDarkTheme.set(this.nativeMatchMediaDark);
        // set to local storage
        this.localStorageService.removeItem('isDarkTheme');
        this.getTheme.set('system');
        // set a flag :root for css
        this.document.documentElement.closest('html')?.classList.remove(Theme.DARK, Theme.LIGHT);
        this.document.documentElement.closest('html')?.classList.add(this.isDarkTheme() ? Theme.DARK : Theme.LIGHT);
    }



    toggleMode() {
        if (this.isDarkTheme()) {
            this.setTheme(false);
            return;
        }
        this.setTheme(true);
        return;
    }
}
