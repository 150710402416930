{
  "name": "if-demo",
  "version": "1.3.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build --configuration integration",
    "build:local": "ng build",
    "watch": "ng build --watch --configuration development",
    "sr": "semantic-release --dry-run --debug"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "18.2.9",
    "@angular/cdk": "18.2.10",
    "@angular/common": "18.2.9",
    "@angular/compiler": "18.2.9",
    "@angular/core": "18.2.9",
    "@angular/forms": "18.2.9",
    "@angular/platform-browser": "18.2.9",
    "@angular/platform-browser-dynamic": "18.2.9",
    "@angular/router": "18.2.9",
    "@if/angular-core-components": "1.3.0",
    "@if/angular-hotkeys": "1.0.5",
    "rxjs": "7.8.1",
    "tailwindcss": "3.4.14",
    "tslib": "2.8.0",
    "zone.js": "0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "18.2.10",
    "@angular/cli": "18.2.10",
    "@angular/compiler-cli": "18.2.9",
    "@if/semantic-release-config-node": "3.0.3",
    "@semantic-release/changelog": "6.0.3",
    "@semantic-release/commit-analyzer": "13.0.0",
    "@semantic-release/exec": "6.0.3",
    "@semantic-release/git": "10.0.1",
    "@semantic-release/gitlab": "13.2.1",
    "@semantic-release/release-notes-generator": "14.0.1",
    "autoprefixer": "10.4.20",
    "conventional-changelog-conventionalcommits": "8.0.0",
    "postcss": "8.4.47",
    "semantic-release": "24.2.0",
    "typescript": "5.5.4"
  },
  "packageManager": "pnpm@9.12.3"
}
