<div>
    <!-- Static sidebar for desktop -->
    <div class="fixed w-full z-50 xl:fixed xl:inset-y-0 xl:flex xl:w-72 xl:flex-col">
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white text-slate-900 dark:text-slate-100 dark:bg-slate-900 px-6 ring-1 ring-white/5">
            <div class="flex h-16 shrink-0 items-center justify-between">
                <h1 class="font-medium text-slate-800 dark:text-white">Angular Components</h1>
                <div class="flex-none flex gap-2">
                    <button class="p-2 rounded-md text-gray-700 dark:text-gray-400 hover:bg-gray-200 dark:bg-gray-800 dark:hover:bg-gray-700" (click)="toggleThemeMode()">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 dark:hidden">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 hidden dark:block">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z" />
                        </svg>
                    </button>
                    <button class="xl:hidden p-2 rounded-md text-gray-700 dark:text-gray-400 hover:bg-gray-200 dark:bg-gray-800 dark:hover:bg-gray-700" (click)="toggleOpen()">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                        </svg>
                    </button>
                </div>
            </div>
            <nav class="xl:flex flex-1 flex-col" [ngClass]="{'flex pb-6 ': isOpen(), 'hidden': !isOpen()}">
                <ul role="list" class="flex flex-1 flex-col gap-y-7">
                    <li>
                        <ul role="list" class="-mx-2 space-y-1">
                            <li>
                                <a routerLink="/"
                                   routerLinkActive="bg-gray-100 text-gray-600 dark:bg-slate-700 dark:text-slate-300 dark:hover:bg-gray-800 dark:hover:text-white"
                                   class="group relative flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 hover:bg-gray-200 dark:hover:bg-slate-600 dark:text-white">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                                    </svg>
                                    Home
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <div class="text-xs font-semibold leading-6 text-gray-600 dark:text-gray-400">Controls</div>
                        <ul role="list" class="-mx-2 space-y-1">
                            <li>
                                <a routerLink="controls/button"
                                routerLinkActive="bg-gray-100 text-gray-600 dark:bg-slate-700 dark:text-slate-300 dark:hover:bg-gray-800 dark:hover:text-white"
                                   class="group relative flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 hover:bg-gray-200 dark:hover:bg-slate-600 dark:text-white">
                                    <svg class="h-6 w-6 shrink-0" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                         stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M15.042 21.672 13.684 16.6m0 0-2.51 2.225.569-9.47 5.227 7.917-3.286-.672ZM12 2.25V4.5m5.834.166-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243-1.59-1.59"/>
                                    </svg>


                                    Button
                                    <ng-container [ngTemplateOutlet]="dimmed"></ng-container>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <div class="text-xs font-semibold leading-6 text-gray-600 dark:text-gray-400">Inputs</div>
                        <ul role="list" class="-mx-2 space-y-1">
                            <li>
                                <a routerLink="inputs/text"
                                routerLinkActive="bg-gray-100 text-gray-600 dark:bg-slate-700 dark:text-slate-300 dark:hover:bg-gray-800 dark:hover:text-white"
                                   class="group relative flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 hover:bg-gray-200 dark:hover:bg-slate-600 dark:text-white">
                                    <svg class="h-6 w-6 shrink-0" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                         stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"/>
                                    </svg>

                                    Text
                                    <ng-container [ngTemplateOutlet]="dimmed"></ng-container>
                                </a>
                            </li>
                            <li>
                                <a routerLink="inputs/number"
                                routerLinkActive="bg-gray-100 text-gray-600 dark:bg-slate-700 dark:text-slate-300 dark:hover:bg-gray-800 dark:hover:text-white"
                                   class="group relative flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 hover:bg-gray-200 dark:hover:bg-slate-600 dark:text-white">
                                    <svg class="h-6 w-6 shrink-0" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                         stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008Zm0 2.25h.008v.008H8.25V13.5Zm0 2.25h.008v.008H8.25v-.008Zm0 2.25h.008v.008H8.25V18Zm2.498-6.75h.007v.008h-.007v-.008Zm0 2.25h.007v.008h-.007V13.5Zm0 2.25h.007v.008h-.007v-.008Zm0 2.25h.007v.008h-.007V18Zm2.504-6.75h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V13.5Zm0 2.25h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V18Zm2.498-6.75h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V13.5ZM8.25 6h7.5v2.25h-7.5V6ZM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 0 0 2.25 2.25h10.5a2.25 2.25 0 0 0 2.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0 0 12 2.25Z"/>
                                    </svg>

                                    Number
                                    <ng-container [ngTemplateOutlet]="dimmed"></ng-container>
                                </a>
                            </li>
                            <li>
                                <a routerLink="inputs/select"
                                routerLinkActive="bg-gray-100 text-gray-600 dark:bg-slate-700 dark:text-slate-300 dark:hover:bg-gray-800 dark:hover:text-white"
                                   class="group relative flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 hover:bg-gray-200 dark:hover:bg-slate-600 dark:text-white">
                                    <svg class="h-6 w-6 shrink-0" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                         stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"/>
                                    </svg>

                                    Select
                                    <ng-container [ngTemplateOutlet]="dimmed"></ng-container>
                                </a>
                            </li>
                            <li>
                                <a routerLink="inputs/checkbox"
                                routerLinkActive="bg-gray-100 text-gray-600 dark:bg-slate-700 dark:text-slate-300 dark:hover:bg-gray-800 dark:hover:text-white"
                                   class="group relative flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 hover:bg-gray-200 dark:hover:bg-slate-600 dark:text-white">
                                    <svg class="h-6 w-6 shrink-0" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                         stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"/>
                                    </svg>

                                    Checkbox
                                    <ng-container [ngTemplateOutlet]="dimmed"></ng-container>
                                </a>
                            </li>
                            <li>
                                <a routerLink="inputs/radio"
                                routerLinkActive="bg-gray-100 text-gray-600 dark:bg-slate-700 dark:text-slate-300 dark:hover:bg-gray-800 dark:hover:text-white"
                                   class="group relative flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 hover:bg-gray-200 dark:hover:bg-slate-600 dark:text-white">
                                    <svg class="h-6 w-6 shrink-0" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                         stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="m3.75 7.5 16.5-4.125M12 6.75c-2.708 0-5.363.224-7.948.655C2.999 7.58 2.25 8.507 2.25 9.574v9.176A2.25 2.25 0 0 0 4.5 21h15a2.25 2.25 0 0 0 2.25-2.25V9.574c0-1.067-.75-1.994-1.802-2.169A48.329 48.329 0 0 0 12 6.75Zm-1.683 6.443-.005.005-.006-.005.006-.005.005.005Zm-.005 2.127-.005-.006.005-.005.005.005-.005.005Zm-2.116-.006-.005.006-.006-.006.005-.005.006.005Zm-.005-2.116-.006-.005.006-.005.005.005-.005.005ZM9.255 10.5v.008h-.008V10.5h.008Zm3.249 1.88-.007.004-.003-.007.006-.003.004.006Zm-1.38 5.126-.003-.006.006-.004.004.007-.006.003Zm.007-6.501-.003.006-.007-.003.004-.007.006.004Zm1.37 5.129-.007-.004.004-.006.006.003-.004.007Zm.504-1.877h-.008v-.007h.008v.007ZM9.255 18v.008h-.008V18h.008Zm-3.246-1.87-.007.004L6 16.127l.006-.003.004.006Zm1.366-5.119-.004-.006.006-.004.004.007-.006.003ZM7.38 17.5l-.003.006-.007-.003.004-.007.006.004Zm-1.376-5.116L6 12.38l.003-.007.007.004-.004.007Zm-.5 1.873h-.008v-.007h.008v.007ZM17.25 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Zm0 4.5a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"/>
                                    </svg>

                                    Radio
                                    <ng-container [ngTemplateOutlet]="dimmed"></ng-container>
                                </a>
                            </li>
                            <li>
                                <a routerLink="inputs/toggle"
                                routerLinkActive="bg-gray-100 text-gray-600 dark:bg-slate-700 dark:text-slate-300 dark:hover:bg-gray-800 dark:hover:text-white"
                                   class="group relative flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 hover:bg-gray-200 dark:hover:bg-slate-600 dark:text-white">
                                    <svg class="h-6 w-6 shrink-0" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                         stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"/>
                                    </svg>

                                    Toggle
                                    <ng-container [ngTemplateOutlet]="dimmed"></ng-container>
                                </a>
                            </li>
                            <li>
                                <a routerLink="inputs/textarea"
                                routerLinkActive="bg-gray-100 text-gray-600 dark:bg-slate-700 dark:text-slate-300 dark:hover:bg-gray-800 dark:hover:text-white"
                                class="group relative flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 hover:bg-gray-200 dark:hover:bg-slate-600 dark:text-white">
                                    <svg class="h-6 w-6 shrink-0" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                         stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"/>
                                    </svg>


                                    Textarea
                                    <ng-container [ngTemplateOutlet]="dimmed"></ng-container>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <div class="text-xs font-semibold leading-6 text-gray-600 dark:text-gray-400">Views</div>
                        <ul role="list" class="-mx-2 space-y-1">
                            <li>
                                <a routerLink="views/banner"
                                   routerLinkActive="bg-gray-100 text-gray-600 dark:bg-slate-700 dark:text-slate-300 dark:hover:bg-gray-800 dark:hover:text-white"
                                   class="group relative flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 hover:bg-gray-200 dark:hover:bg-slate-600 dark:text-white">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 1 1 0-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 0 1-1.44-4.282m3.102.069a18.03 18.03 0 0 1-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 0 1 8.835 2.535M10.34 6.66a23.847 23.847 0 0 0 8.835-2.535m0 0A23.74 23.74 0 0 0 18.795 3m.38 1.125a23.91 23.91 0 0 1 1.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 0 0 1.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 0 1 0 3.46" />
                                    </svg>



                                    Banner
                                    <ng-container [ngTemplateOutlet]="dimmed"></ng-container>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <div class="text-xs font-semibold leading-6 text-gray-600 dark:text-gray-400">Plugins</div>
                        <ul role="list" class="-mx-2 space-y-1">
                            <li>
                                <a routerLink="libs/hotkeys"
                                   routerLinkActive="bg-gray-100 text-gray-600 dark:bg-slate-700 dark:text-slate-300 dark:hover:bg-gray-800 dark:hover:text-white"
                                   class="group relative flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 hover:bg-gray-200 dark:hover:bg-slate-600 dark:text-white">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.362 5.214A8.252 8.252 0 0 1 12 21 8.25 8.25 0 0 1 6.038 7.047 8.287 8.287 0 0 0 9 9.601a8.983 8.983 0 0 1 3.361-6.867 8.21 8.21 0 0 0 3 2.48Z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 18a3.75 3.75 0 0 0 .495-7.468 5.99 5.99 0 0 0-1.925 3.547 5.975 5.975 0 0 1-2.133-1.001A3.75 3.75 0 0 0 12 18Z" />
                                    </svg>


                                    Hot Keys
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
                <div class="text-center text-xs text-slate-400 hidden xl:block py-2"> &copy; {{ year }} InterFace AG - Version: v2.0.5</div>
            </nav>
        </div>
    </div>

    <div class="xl:pl-72">
        <main>
            <router-outlet/>
        </main>
    </div>
</div>


<ng-template #dimmed>
    <div class="w-6 h-6 flex justify-center items-center rounded-sm absolute right-2 bg-gray-200 text-slate-800 dark:bg-slate-800 dark:text-gray-500"
    title="dark mode supported">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-3">
            <path stroke-linecap="round" stroke-linejoin="round" d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z" />
        </svg>
    </div>
</ng-template>

<ng-template #soon>
    <div class="w-6 h-6 flex justify-center items-center rounded-sm absolute right-2 bg-gray-200 text-slate-800 dark:bg-slate-800 dark:text-gray-500"
    title="feature in build">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-3">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
        </svg>
    </div>
</ng-template>