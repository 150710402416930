import {Component, inject, signal} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterLink, RouterLinkActive, RouterOutlet} from '@angular/router';
import {Title} from "@angular/platform-browser";
import {NgClass, NgTemplateOutlet} from "@angular/common";
import {version} from '../../package.json';
import {ThemeService} from "./shared/services/theme/theme.service";
import {SharedLibService} from "./shared/shared.lib";

@Component({
    selector: 'if-demo-root',
    imports: [RouterOutlet, RouterLink, RouterLinkActive, NgClass, NgTemplateOutlet],
    providers: [ThemeService, SharedLibService],
    templateUrl: 'app.component.html',
    styles: []
})
export class AppComponent {
    isOpen = signal<boolean>(false);
    year = new Date().getFullYear();
    version = version;
    theme: ThemeService = inject(ThemeService);

    constructor(private router: Router,
                private route: ActivatedRoute,
                private titleService: Title,) {

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const child: ActivatedRoute | null = this.route.firstChild;
                const title = child && child.snapshot.data['title'];
                this.titleService.setTitle(`@IF - ${title}`);
                this.isOpen.set(false);
            }
        });
    }

    toggleOpen() {
        this.isOpen.set(!this.isOpen());
    }

    toggleThemeMode() {
        this.theme.toggleMode();
    }
}
