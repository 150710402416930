import {Component} from '@angular/core';
import {DemoWrapperComponent} from "../../shared/demo-wrapper/demo-wrapper.component";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {SharedLib} from "../../shared/shared.lib";

@Component({
  selector: 'if-demo-home',
  standalone: true,
    imports: [
        DemoWrapperComponent,
        ReactiveFormsModule,
        ...SharedLib
    ],
  templateUrl: './home.component.html',
  styles: ``
})
export class HomeComponent {

    form = new FormGroup({
        demo: new FormControl(null, [Validators.required])
    });

}
